<template>
  <section class="content-section">
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-md-10 col-lg-8 mx-auto text-center">
          <h2 class="text-white mb-5">Land of Mischief</h2>
        </div>
      </div>
    </div>
    <div class="container px-4 px-lg-5">
      <div class="row">
        <div class="mb-3" id="nft-transfer-options">
          <div class="input-group">
            <input type="text" v-model="address" class="form-control" placeholder="0x..." />
            <button class="btn btn-primary" @click="updateRats()">View!</button>
          </div>
        </div>
      </div>
    </div>

    <div class="container align-content-center">
      <div class="row" v-if="address == null && rats.length == 0">
        <div class="col-md-12 text-center">
          <h1>Top 25 - {{ $store.getters.config.chainName }}</h1>
        </div>
        <div class="subtitle text-center"> Total holders: {{ total }} </div>
        <table>
          <tr v-for="owner in owners" :key="owner.number">
            <td style="width: 5em;"># {{owner.number}}</td>
            <td style="padding-right: 1em; text-align:right">
              <strong>{{owner.amount}}</strong> $RATs
            </td>
            <td class="iq">
              {{ $store.getters.config.power }}: {{owner.power}} {{ $store.getters.config.powerIcon }} &nbsp;
            </td>
            <td>
              <MetamaskAddress v-bind:address="owner.address" placeholder="true" short="yes"></MetamaskAddress>
            </td>
          </tr>
        </table>
      </div>

      <div class="row rats" v-if="address && rats.length == 0">
        <p class="text-center">$RATs have not spread to this address yet.</p>
      </div>
      <div class="row rats" v-if="rats.length > 0">
        <p class="text-center">
          <MetamaskAddress v-bind:address="address"></MetamaskAddress>
        </p>
        <div class="subtitle" id="ratCount">{{rats.length}} $RATs</div>
        <template v-for="rat in rats" :key="rat">
          <div class="col-lg-2 col-md-3 col-sm-6 text-center rat-card" v-if="typeof rat !== 'undefined'">
            <router-link v-bind:to="'/rat/id/' + rat.id">
              <img v-bind:src="$store.state.nfts[rat.id].metadata.image" class="img-fluid rat-image" /><br/>
              <span style='height:60px;display:block;'>{{$store.state.nfts[rat.id].metadata.name }}</span>
            </router-link>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import MetamaskAddress from "@/components/MetamaskAddress";
export default {
  components: {MetamaskAddress},
  data() {
    return {
      total: 0,
      owners: [],
      address: null,
      rats: [],
    }
  },
  watch: {
    '$route.params.address'(value) {
      if(this.address !== value) {
        this.rats = [];
      }
      this.address = value;
      this.updateRats();
    },
    '$store.state.nftContract'() {
      this.updateRats();
    }
  },
  mounted() {
    fetch(this.api_url + '/nightmares/mischief').then((response) => {
      return response.json();
    }).then((data) => {
      this.total = data.total;
      this.owners = data.list;
      for(let i in this.owners) {
        this.$store.state.nftContract.power(this.owners[i].address).then(power => {
          this.owners[i].power = power;
        });
      }
    });

    this.address = this.$route.params.address;
    if(this.$route.params.address !== null) {
      this.updateRats();
    }
  },
  methods: {
    updateRats : function() {
      if(this.address && this.$store.state.nftContract) {
        this.rats = [];
        this.$store.state.nftContract.walletOfOwner(this.address).then((result) => {
          let results = result;
          for (let i in results) {
            let nftId = parseInt(results[i].toString());
            if(nftId > 0) {
              if(this.$store.state.nfts[nftId]) {
                if(this.$store.getters.config.baseSupply > nftId) {
                  for(var n in this.rats) {
                    if(this.rats[n].id === nftId) {
                      continue;
                    }
                  }
                  this.rats.push({id: nftId});
                }
                continue;
              }
              this.$store.state.nftContract.tokenURI(nftId).then((uri) => {
                return fetch(uri).then((result) => {
                  return result.json();
                }).then(data => {
                  this.$store.commit('loaded');
                  this.$store.commit('nft', {
                    metadata: data,
                    id: nftId,
                    slot: data.slot,
                    slotName: data.slotName,
                    image: data.image,
                  });
                  if(this.$store.getters.config.baseSupply > nftId) {
                    for(var n in this.rats) {
                      if(this.rats[n].id === nftId) {
                        return;
                      }
                    }
                    this.rats.push({id: nftId});
                  }
                });
              }).catch((e) => {
                console.log(e);
                console.log('Error??');
              });
            }
          }
        });
      }
    }
  }
}
</script>